import { downloadFile, getResFilename, withLoading } from 'prace-common-components'
import { clientApi } from '..'
import { CINESGetAppsApiPayload } from './types'

export const cinesApi = clientApi.injectEndpoints({
  endpoints: (build) => ({
    //TODO: Handle any
    getCINESApps: build.query<any, CINESGetAppsApiPayload>({
      ...withLoading,
      query: ({ limit, offset, search = '' }) => ({
        url: `/cines?limit=${limit}&offset=${offset}&search=${search}`,
      }),
    }),
    //TODO: Handle any
    getCINESApp: build.query<any, StringNumber>({
      ...withLoading,
      query: (id) => ({
        url: `/cines/${id}`,
      }),
    }),
    downloadCinesFile: build.query<void, string>({
      query: (fileNameExtension) => ({
        url: `/cines/file/${fileNameExtension}`,
        responseHandler: async (res) =>
          downloadFile(getResFilename(res), await res.blob()),
        cache: 'no-cache',
      }),
    }),
  }),
})

export const {
  useGetCINESAppQuery,
  useGetCINESAppsQuery,
  useLazyDownloadCinesFileQuery,
} = cinesApi
