import { configureStore } from '@reduxjs/toolkit'
import { clientApi } from './api'
import { authReducer } from './auth'
import { loadingReducer, notificationsReducer } from 'prace-common-components'
import { usersReducer } from './users'
import { profileReducer } from './profile'
import { cinesReducer } from './cines'
import { callsReducer } from './calls'
import { createBrowserHistory } from 'history'
import { organizationReducer } from './organization'

export const history = createBrowserHistory()

/* TODO: Do we need to implement this? */
/**
 * Redux middleware that captures exceptions with Sentry
 * @example
 * import { createStore, applyMiddleware } from 'redux'
 * import { sentryReduxMiddleware } from 'integrations/sentry'
 *
 * const store = createStore(reducer, applyMiddleware(sentryReduxMiddleware))
 */
/* export const sentryReduxMiddleware = () => (next: Dispatch<Action>) => (action: Action) => {
  try {
    return next(action)
  } catch (error) {
    Sentry.captureException(error)
    throw error
  }
} */

export const store = configureStore({
  reducer: {
    auth: authReducer,
    users: usersReducer,
    calls: callsReducer,
    notifications: notificationsReducer,
    loading: loadingReducer,
    profile: profileReducer,
    cines: cinesReducer,
    organization: organizationReducer,
    [clientApi.reducerPath]: clientApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(
    clientApi.middleware,
    //sentryReduxMiddleware,
  ),
  devTools: ENV !== 'production',
})

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
