export const activeTimeFrame = (firstStep) => {
    if (firstStep?.timeframes?.length) {
        const today = new Date();
        const activeTimeframe = [...firstStep.timeframes].reverse().find((timeframe) => {
            const timeframeStart = new Date(timeframe.start);
            if (timeframe.end) {
                const timeframeEnd = new Date(timeframe.end);
                return ((timeframeStart.getTime() <= today.getTime()) && (timeframeEnd.getTime() >= today.getTime()));
            }
            else {
                return (timeframeStart.getTime() <= today.getTime());
            }
        });
        return activeTimeframe;
    }
    else {
        return undefined;
    }
};
