import { User } from 'store/users/types'
import { clientApi } from '..'
import { LoadUserSuggestionsPayload, UserSuggestionsResponse } from './types'
import { Profile } from 'store/profile/types'
import { ROLES } from 'prace-common-components'

export const usersApi = clientApi.injectEndpoints({
  endpoints: (build) => ({
    getUsersByIds: build.mutation<Profile[], number[]>({
      query: (body) => ({
        url: '/users',
        method: 'POST',
        body,
      }),
    }),
    getRoles: build.query<ROLES[], void>({
      keepUnusedDataFor: 300, /* 5 minutes cache */
      query: () => ({ url: '/users/roles' }),
    }),
    getUsersByApplication: build.query<Partial<User>[], number>({
      query: (applicationId) => ({
        url: `/users/application/${applicationId}`,
      }),
    }),
    loadUserSuggestions: build.mutation<UserSuggestionsResponse, LoadUserSuggestionsPayload>({
      query: (body) => ({
        url: '/users/suggestions',
        method: 'POST',
        body,
      }),
      transformResponse: (res: UserSuggestionsResponse, _meta, args) => ({ ...args, ...res }),
    }),
  }),
})

export const {
  useGetRolesQuery,
  useGetUsersByIdsMutation,
  useLazyGetUsersByApplicationQuery,
  useLoadUserSuggestionsMutation,
} = usersApi
