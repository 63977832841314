import * as React from 'react'
import Grid from '@material-ui/core/Grid'
import { NewButton } from 'prace-common-components'
import { AcceptModalProps } from './types'
import { $ModalContainer, $Container, $POCText } from './styles'

export const AcceptModal: React.FC<AcceptModalProps> = ({ coi, handleAccept, handleCancel, loading }) => {

  return (
    <$ModalContainer>
      <$POCText>{coi}</$POCText>
      <$Container container justifyContent='space-between'>
        <Grid item xs={3}>
          <NewButton loading={loading} onClick={handleAccept}>I Agree</NewButton>
        </Grid>
        <Grid item xs container justifyContent='flex-end'>
          <NewButton disabled={loading} variant='outlined' onClick={handleCancel}>Cancel</NewButton>
        </Grid>
      </$Container>
    </$ModalContainer>
  )
}
