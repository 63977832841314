import React, { FC, useState, useEffect, useRef,useMemo, useCallback } from 'react'
import { VerticalTabs, Title, isValidPassword, NotificationType, SvgIcon } from 'prace-common-components'
import { ProfileInput } from './types'
import {
  $Profile,
  $UserInfo,
  $Avatar,
  $LastLogin,
  $Form,
  $UserCard,
  $SaveButton,
  $Name,
  $BackButton,
} from './styles'
import { Personal, Additional, Password, Involvement, Bank } from './views'
import { useUpdateEmailMutation, useUpdatePasswordMutation, useUpdateProfileMutation } from 'store/api/profile'
import { RootState } from 'store'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { useTheme } from 'styled-components'

const tabs = [
  { id: 0, label: 'Personal Information' },
  { id: 1, label: 'Additional Information' },
  { id: 2, label: 'Involvement' },
  { id: 3, label: 'Bank Details' },
  { id: 4, label: 'Password' },
]

export const Profile: FC<{isMobile?: boolean}> = ({ isMobile }) => {
  const dispatch = useAppDispatch()
  const theme = useTheme() as { colors: { primary: string }}
  const organization = useAppSelector((state: RootState) => state.organization.theme?.organization)
  const profile = useAppSelector((state: RootState) => state.profile.user)
  const isFirstRender = useRef(true)
  const [saveDisabled, disableSave] = useState(true)
  const [mobileOpen, setMobileOpen] = useState(isMobile)
  const [menu, setMenu] = useState(0)
  const [updateEmail, { isLoading: isLoadingEmail }] = useUpdateEmailMutation()
  const [updatePassword, { isLoading: isLoadingPassword }] = useUpdatePasswordMutation()
  const [updateProfile, { isLoading: isLoadingProfile }] = useUpdateProfileMutation()

  const [passInput, setPassInput] = useState({ oldPassword: '', newPassword: '', verifyPassword: '' })

  const loading = isLoadingEmail || isLoadingPassword || isLoadingProfile

  const organizationName = organization?.shortName || 'PRACE'
  const passView = menu === 4

  const MIN_AGE = 18
  const MAX_AGE = 100
  const today = new Date()
  const dateMin = new Date(today.getFullYear() - MAX_AGE, today.getMonth()).toISOString().split('T')[0]
  const dateMax = new Date(today.getFullYear() - MIN_AGE, today.getMonth()).toISOString().split('T')[0]

  /** Set Profile data */
  useEffect(() => {
    if (profile) {
      const {id, lastLogin, email, fields, ...userInput } = profile
      const inputFields = fields === null ? [] : fields
      setInput(({...userInput, fields: inputFields}))
    }
    if (isFirstRender.current) isFirstRender.current = false
  }, [profile])

  const [input, setInput] = useState<ProfileInput>({
    firstName: '',
    lastName: '',
    title: '',
    gender: '',
    nationality: '',
    phoneNumber: '',
    dateOfBirth: dateMax,
    iban: '',
    homeAddress: '',
    bankName: '',
    bankHolderName: '',
    swiftCode: '',
    bankAddress: '',
    bankRouting: '',
    bankRemarks: '',
    mandateStartDate: '',
    mandateEndDate: '',
    temporary: false,
    website: '',
    linkedin: '',
    researchgate: '',
    scholar: '',
    scopus: '',
    link1: '',
    link2: '',
    reviewersAvailability: false,
    fields: [],
    acceptMailingList: null,
  })

  /** profile form error conditions */
  const formError = useMemo(() => ({
    firstName: input.firstName?.length < 1,
    lastName: input.lastName?.length < 1,
    title: input.title?.length < 1,
    gender: input.gender?.length < 1,
    nationality: input.nationality?.length < 1,
    dateOfBirth: !input.dateOfBirth || input.dateOfBirth < dateMin || input.dateOfBirth > dateMax,
    fields: input.fields?.length < 1,
    phoneNumber: false, //TODO: Do we want to validate phone numbers here in the future?
  }), [input, dateMin, dateMax])

  /** complete profile form error conditions for non appplicants */
  const formErrorNonApplicant = useMemo(() => ({
    homeAddress: input.homeAddress.length < 1,
  }), [input])

  /** password form error conditions */
  const formPassError = useMemo(() => ({
    oldPasswordEmpty: passInput.oldPassword.length === 0,
    oldPassword: !(passInput.oldPassword.length === 0) && !isValidPassword(passInput.oldPassword),
    newPassword: (passInput.oldPassword.length > 0 && passInput.newPassword.length === 0) ||
      (!(passInput.newPassword.length === 0) && !isValidPassword(passInput.newPassword)),
    verifyPassword: passInput.newPassword !== passInput.verifyPassword,
  }), [passInput])

  /** Checks if save button should be enabled/disabled */
  useEffect(() => {
    // Validates Profile changes
    if (!passView) {
      // check if form errors exist
      for (const val of Object.values(formError)) {
        if (val) {
          disableSave(true)
          return
        }
      }

      if (!loading) disableSave(false)

      return

    } else {
      for (const val of Object.values(formPassError)) {
        if (val) {
          disableSave(true)
          return
        }
      }

      if (!loading) disableSave(false)

      return
    }

  },[
    input, formError, menu, passView,
    loading, saveDisabled,
    formErrorNonApplicant, formPassError,
  ])

  /** Profile view inputs handler */
  const handleInput = useCallback((name: string, value: Nullable<StringNumber | boolean>) => {
    setInput((input) => ({...input, [name]: name === 'phoneNumber' && !value ? '' : value as string}))
  }, [])

  const handleDateChange = useCallback((name: string, value: StringNumber) => {
    const birthDate = (value as string).split('T')[0]
    if (name) setInput((input) => ({...input, [name]: birthDate}))
  }, [])

  const handleSelectChange = useCallback((name: string, value: Nullable<StringNumber>) => {
    setInput((input) => ({...input, [name]: value as string }))
  }, [])

  const handleToggleChange = useCallback((name: string, value: string) => {
    setInput((input) => ({ ...input, [name]: value === 'true'}))
  }, [])

  const handleFieldsChange = useCallback((idx: number) => (_name: string, value: Nullable<StringNumber>) => {
    setInput((input) => {
      const fields = [...input.fields]
      fields[idx] = value as string
      return {...input, fields}})
  }, [])

  const handlePassInput = useCallback((name: string, value: Nullable<StringNumber>) => {
    setPassInput((passInput) => ({...passInput, [name]: value as string}))
  }, [])

  /** handles save button update */
  const handleProfileUpdate = async () => {
    if (profile) {
      try {
        await updateProfile({...profile, ...input, isReviewer: String(input.reviewersAvailability) == 'true'}).unwrap()
        dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'Profile saved successfully' } })
      } catch (err) {
        console.log(err)
      }
    }
  }

  const changePassword = async () => {
    try {
      await updatePassword(passInput).unwrap()
      dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'Password updated successfully' } })
    } catch (err) {
      console.log(err)
    }
  }

  const changeAdditional = async () => {
    try {
      await updateProfile({...profile, ...input, isReviewer: String(input.reviewersAvailability) == 'true'}).unwrap()
      dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'Profile saved successfully' } })
    } catch (err) {
      console.log(err)
    }
  }

  const handleUpdateEmail = async (email: string, password: string) => {
    await updateEmail({ email, password }).unwrap()
    dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'Confirmation email sent' } })
  }

  const profileView = (menu: number) => {
    const showErrors = !loading && !isFirstRender.current
    switch(menu) {
      case 1:
        return <Additional
          input={input}
          showErrors={showErrors}
          handleInput={handleInput}
          changeAdditional={changeAdditional}
        />
      case 2:
        return <Involvement
          isMobile={isMobile}
          handleProfileUpdate={handleProfileUpdate}
          input={input}
          name={organizationName}
          showErrors={showErrors}
          handleDateChange={handleDateChange}
          handleToggleChange={handleToggleChange}
        />
      case 3:
        return <Bank
          isMobile={isMobile}
          handleProfileUpdate={handleProfileUpdate}
          name={organizationName}
          input={input}
          showErrors={showErrors}
          handleInput={handleInput}
        />
      case 4:
        return <Password
          formPassError={formPassError}
          passInput={passInput}
          showErrors={showErrors}
          handlePassInput={handlePassInput}
          changePassword={changePassword}
        />
      default:
        return <Personal
          isMobile={isMobile}
          handleProfileUpdate={handleProfileUpdate}
          input={input}
          name={organizationName}
          showErrors={showErrors}
          profile={profile}
          formError={formError}
          formErrorNonApplicant={formErrorNonApplicant}
          handleInput={handleInput}
          handleSelectChange={handleSelectChange}
          handleDateChange={handleDateChange}
          handleFieldsChange={handleFieldsChange}
          updateEmail={handleUpdateEmail}
        />
    }
  }

  return (
    <$Profile isMobile={isMobile}>
      {isMobile && !mobileOpen && <$BackButton onClick={() => setMobileOpen(true)}>
        <SvgIcon name='chevron-left' size='20' color={theme.colors.primary} />
          Back to Menu
      </$BackButton>}
      {(mobileOpen || !isMobile) && <$UserInfo>
        <$UserCard>
          <Title alternate>Profile</Title>
          <$Avatar>{`${profile.firstName.charAt(0)}${profile.lastName.charAt(0)}`}</$Avatar>
          <$Name>{`${profile.firstName || ''} ${profile.lastName || ''}`}</$Name>
          <$LastLogin>
            <b>Last Login</b> {new Date(profile.lastLogin).toDateString()}
          </$LastLogin>
          {!isMobile && 
            <$SaveButton onClick={handleProfileUpdate} disabled={saveDisabled || passView}>Save Changes</$SaveButton>
          }
        </$UserCard>
        <VerticalTabs noValue={isMobile} tabs={tabs} value={menu} onChange={(t: number) => {
          setMobileOpen(false)
          setMenu(t)
        }}/>
      </$UserInfo>}
      {(!mobileOpen || !isMobile) && <$Form isMobile={isMobile}>
        {profileView(menu)}
      </$Form>}
    </$Profile>
  )
}
