import React, { FC } from 'react'
import { Input, NewButton, Title } from 'prace-common-components'
import { Grid } from '@material-ui/core'
import { $GridItem } from './styles'
import { AdditionalProps } from './types'

export const Additional: FC<AdditionalProps> = ({ input, changeAdditional, handleInput }) => {

  return <>
    <Grid container>
      <$GridItem item xs={12}>
        <Title alternate>Additional Information</Title>
      </$GridItem>
      <$GridItem item xs={12} md={7}>
        <Input
          title='Linkedin'
          name='linkedin'
          placeholder='www.linkedin.com/in/xxxxx/'
          value={input.linkedin}
          onChange={handleInput}
          hideItalic
        />
      </$GridItem>
      <$GridItem item xs={12} md={7}>
        <Input
          title='Research Gate'
          name='researchgate'
          placeholder='www.researchgate.net/profile/xxxxx'
          value={input.researchgate}
          onChange={handleInput}
          hideItalic
        />
      </$GridItem>
      <$GridItem item xs={12} md={7}>
        <Input
          title='Google Scholar'
          name='scholar'
          placeholder='www.scholar.google.pt/citations?user=xxxxx'
          value={input.scholar}
          onChange={handleInput}
          hideItalic
        />
      </$GridItem>
      <$GridItem item xs={12} md={7}>
        <Input
          title='Scopus'
          name='scopus'
          placeholder='www.scopus.com/authid/detail.uri?authorId=xxxxx'
          value={input.scopus}
          onChange={handleInput}
          hideItalic
        />
      </$GridItem>
      <$GridItem item xs={12} md={7}>
        <Input
          title='Website'
          name='website'
          value={input.website}
          onChange={handleInput}
          hideItalic
        />
      </$GridItem>
      <$GridItem item xs={12} md={7}>
        <Input
          title='Other Link #1'
          name='link1'
          placeholder='www.orcid.org/xxxx-xxxx-xxxx-xxxx'
          value={input.link1}
          onChange={handleInput}
          hideItalic
        />
      </$GridItem>
      <$GridItem item xs={12} md={7}>
        <Input
          title='Other Link #2'
          name='link2'
          value={input.link2}
          onChange={handleInput}
          hideItalic
        />
      </$GridItem>
      <$GridItem item xs={12}>
        <NewButton onClick={changeAdditional}>Save Changes</NewButton>
      </$GridItem>
    </Grid>
  </>
}
