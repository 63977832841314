import React, { FC, useState, FormEvent } from 'react'
import { activateCaptcha, isValidPassword, isEmail, Input, NewButton } from 'prace-common-components'
import { $Form } from './styles'
import Terms from 'components/Terms'
import Grid from '@material-ui/core/Grid'
import { useRegisterMutation } from 'store/api/auth'
import { useAppSelector } from 'store/hooks'
import { RootState } from 'store'

const validations = {
  firstName: (v: string) => v === '' || !v.length,
  lastName: (v: string) => v === '' || !v.length,
  email: (v: string) => v === '' || !isEmail(v),
  password: (v: string) => v === '' || !isValidPassword(v),
  confirmPassword: (pass: string, confirmPass: string) => !!pass.length && pass !== confirmPass,
}

export const Register: FC<{onClickLogin?: () => void}> = ({ onClickLogin }) => {
  const [register, { isLoading }] = useRegisterMutation()
  const theme = useAppSelector((state: RootState) => state.organization.theme)

  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,
    password: false,
    confirmPassword: false,
    accept: true,
  })
  const [accept, setAcceptance] = React.useState(false)

  const handleRegister = async (e: FormEvent<HTMLFormElement> | KeyboardEvent) => {
    const target = e.target as typeof e.target & {
      firstName: { value: string },
      lastName: { value: string },
      email: { value: string },
      password: { value: string },
      confirmPassword: { value: string },
    }
    const valueErrors = {
      firstName: validations.firstName(target.firstName.value),
      lastName: validations.lastName(target.lastName.value),
      email: validations.email(target.email.value),
      password: validations.password(target.password.value),
      confirmPassword: validations.confirmPassword(target.password.value, target.confirmPassword.value),
      accept: !accept,
    }
    setErrors(valueErrors)
    if (!Object.entries(valueErrors).some((v) => v[1])) {
      try {
        await register({
          firstName: target.firstName.value,
          lastName: target.lastName.value,
          email: target.email.value,
          password: target.password.value,
        }).unwrap()
      } catch (err) {
        console.log(err)
      }
    }
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement> | KeyboardEvent) => {
    e.preventDefault()
    activateCaptcha((res: 0 | 1 | 2) => {
      if(res === 1) handleRegister(e)
    })
  }

  const handleCheckbox = (checked: boolean) => {
    setAcceptance(checked)
  }

  return (
    <$Form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Input
            title='First Name'
            name='firstName'
            type='text'
            required
            error={errors.firstName}
            errorMsg={errors.firstName ? 'First Name is required.' : undefined}
            hideItalic
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            title='Last Name'
            name='lastName'
            type='text'
            required
            error={errors.lastName}
            errorMsg={errors.lastName ? 'Last Name is required.' : undefined}
            hideItalic
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            title='E-mail'
            name='email'
            type='email'
            description="Please use your organisation's official email address"
            boldDescription
            required
            error={errors.email}
            errorMsg={errors.email ? 'Invalid E-mail Address.' : undefined}
            hideItalic
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            title='Password'
            name='password'
            type='password'
            description='Password must contain !”#%&7.'
            required
            error={errors.password}
            errorMsg={errors.password ? 'Password must have one lowercase, one uppercase, one symbol and be at least 12 characters long.' : undefined}
            hideItalic
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            title='Confirm Password'
            name='confirmPassword'
            type='password'
            required
            error={errors.confirmPassword}
            errorMsg={errors.confirmPassword ? 'Passwords mismatch.' : undefined}
            hideItalic
          />
        </Grid>
        <Grid item xs={12}>
          <Terms
            checkboxText='I accept the'
            privacyURL={theme?.privacy.link || 'https://prace-ri.eu/legal-corporate/privacy-notice'}
            termsURL={theme?.terms.link || 'https://prace-ri.eu/legal-corporate/legal-notice-terms-of-use'}
            handleChange={handleCheckbox}
          />
        </Grid>
        <Grid item xs={12}>
          <NewButton
            fullWidth
            disabled={isLoading || !accept}
            loading={isLoading}
            type='submit'
          >
            Register
          </NewButton>
        </Grid>
        <Grid item xs={12}>
          <NewButton
            fullWidth
            onClick={onClickLogin}
            variant='outlined'
          >
            Already have an account?
          </NewButton>
        </Grid>
      </Grid>
    </$Form>
  )
}
