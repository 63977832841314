import React, { FC, useState, useEffect } from 'react'
import { Login, Register } from './views'
import { useNavigate, useLocation } from 'react-router-dom'
import { Landing as LandingContainer } from 'prace-common-components'
import { CDN, Routes } from 'constants/global'

export const Landing: FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [view, setView] = useState<'login' | 'register'>('login')

  useEffect(() => {
    setView(location.pathname === Routes.LOGIN ? 'login' : 'register')
  },[location.pathname])

  const isLogin = view === 'login'
  return (
    <LandingContainer image={`${CDN}/${isLogin ? 'login' : 'register'}.jpg`} logo={`${CDN}/logo.png`} description='Please, login or register for free'>
      {isLogin ?
        <Login onClickRegister={() => navigate(Routes.REGISTER)} /> :
        <Register onClickLogin={() => navigate(Routes.LOGIN)} />
      }
    </LandingContainer>
  )
}
