import {NewModal, NotificationType} from 'prace-common-components'
import { Routes as ConstantRoutes } from 'constants/global'
import React, { FC, PropsWithChildren, useContext } from 'react'
import { Navigate } from 'react-router-dom'
import { AcceptMailingListModal } from 'components/AcceptMailingListModal/AcceptMailingListModal'
import { UserInfoContext } from 'components/UserInfo/UserInfo'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { RootState } from 'store'
import { useUpdateProfileMutation } from 'store/api/profile'

export const PrivateRoute: FC<PropsWithChildren<any>> = ({ children }) => {
  const dispatch = useAppDispatch()
  const profile = useAppSelector((state: RootState) => state.profile.user)
  const isAuth = useAppSelector((state: RootState) => state.auth.isAuth)
  const user = useAppSelector((state: RootState) => state.auth.user)
  const theme = useAppSelector((state: RootState) => state.organization.theme)
  const [updateProfile] = useUpdateProfileMutation()

  const userInfoContext = useContext(UserInfoContext)

  if (!isAuth || !user) return <Navigate to={ConstantRoutes.LOGIN} />

  // After the login this wrapper component will be called,
  // then I think here is the best location to check if the profile already accepts or not to join
  // the mailing list, thus it dispatches an event to trigger the modal.
  // TODO: This should be done in another way
  if (
    !!profile.email/* !profile.isWorking */ && profile.acceptMailingList === null
    && !userInfoContext.mailingListIgnored
  ) {
    const handleUpdateProfile = async (accepted: Nullable<boolean>) => {
      const saveProfile = {
        ...profile,
        acceptMailingList: accepted,
      }

      // As this property is not part of the ProfileType,
      // I delete `isWorking` to have the Profile Payload prepared to dispatch
      //delete saveProfile.isWorking

      try {
        await updateProfile(saveProfile).unwrap()
        dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'Profile saved successfully' } })
      } catch (err) {
        console.log(err)
      }
    }

    return (
      <>
        <NewModal
          open
          title='Do you want to join our mailing list?'
          onClose={() => {
            userInfoContext.ignoreMailingList()
          }}
        >
          <AcceptMailingListModal
            name={theme?.organization.shortName}
            handleResult={(accepted) => {
              handleUpdateProfile(accepted)
            }}
          />
        </NewModal>
        {children}
      </>
    )
  }

  if (user) return children
  return null
}
