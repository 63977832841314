import * as React from 'react'
import { NewButton } from 'prace-common-components'
import { $Container, $ModalContainer, $NoticeText } from './styles'
import { ApplyModalProps } from './types'
import Grid from '@material-ui/core/Grid'

export const ApplyModal: React.FC<ApplyModalProps> = ({ handleApply, handleDashboard, handleCancel }) => {
  return (
    <$ModalContainer>
      <$NoticeText>
        Please note that you already have an open proposal for this call.
        If you wish to continue editing that, please go to your dashboard to access it.
        If you wish to create a further proposal please proceed.
      </$NoticeText>
      <$Container container justifyContent='space-between'>
        <Grid item xs={2}>
          <NewButton onClick={handleApply}>Apply</NewButton>
        </Grid>
        <Grid item xs={3}>
          <NewButton variant='outlined' onClick={handleDashboard}>Go to Dashboard</NewButton>
        </Grid>
        <Grid item xs container justifyContent='flex-end'>
          <NewButton variant='outlined' onClick={handleCancel}>Cancel</NewButton>
        </Grid>
      </$Container>
    </$ModalContainer>
  )
}
