import { activeTimeFrame, FlowCall } from 'prace-common-components'
import dateUTC from 'util/dateUTC'

//TODO: Add the call status onGoing
export function getCallStatus(call: FlowCall) {
  const { published, flow = [] } = call

  if(!flow.length) return false

  const now = dateUTC()
  /* Check timeframes of first step */
  const timeframe = activeTimeFrame(flow.find((step) => step.order === 0))

  const startDate = dateUTC(timeframe?.start || undefined)
  const endDate = timeframe?.end ? dateUTC(timeframe?.end || undefined) : undefined

  const open = published && timeframe && (now >= startDate) && (endDate ? endDate >= now : true)

  return open
}
