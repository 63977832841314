import * as React from 'react'
import Grid from '@material-ui/core/Grid'
import {$ModalContainer} from 'components/AcceptMailingListModal/styles'
import {AcceptMailingListModalProps} from 'components/AcceptMailingListModal/types'
import {NewButton} from 'prace-common-components'
import {$Container, $NoticeText} from 'components/AcceptMailingListModal/styles'

export const AcceptMailingListModal: React.FC<AcceptMailingListModalProps> = ({
  handleResult,
  name,
}) => {
  return (
    <$ModalContainer>
      <$NoticeText>
        {`By clicking accept, you agree to receive information about upcoming ${name || 'PRACE and EuroHPC'} events and relevant news.`}
      </$NoticeText>
      <$Container container justifyContent='space-between'>
        <Grid item xs={2}>
          <NewButton variant='contained' onClick={() => handleResult(true)}>Accept</NewButton>
        </Grid>
        <Grid item xs container justifyContent='flex-end'>
          <NewButton variant='outlined' onClick={() => handleResult(false)}>Reject</NewButton>
        </Grid>
      </$Container>
    </$ModalContainer>
  )
}
