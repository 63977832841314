import * as React from 'react'
import Grid from '@material-ui/core/Grid'
import { NewButton, Input } from 'prace-common-components'
import { RejectModalProps } from './types'
import { $ModalContainer, $Grid, $Container } from './styles'

export const RejectModal: React.FC<RejectModalProps> = ({ handleReject, handleCancel, loading }) => {
  const [reason, setReason] = React.useState<string>('')

  const onChange = (_name: string, value: Nullable<StringNumber>) => setReason(value as string)

  const submitReject = () => handleReject(reason)

  return (
    <$ModalContainer>
      <>
        <$Grid container>
          <Grid item xs={12}>
            <Input onChange={onChange} title='Reason for rejection' name='reason' multiline minRows={5} type='text' hideItalic/>
          </Grid>
        </$Grid>
        <$Container container justifyContent='space-between'>
          <Grid item xs={3}>
            <NewButton loading={loading} onClick={submitReject}>Reject</NewButton>
          </Grid>
          <Grid item xs container justifyContent='flex-end'>
            <NewButton variant='outlined' onClick={handleCancel}>Cancel</NewButton>
          </Grid>
        </$Container>
      </>
    </$ModalContainer>
  )
}
