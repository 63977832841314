import React, { FC } from 'react'
import { Input, Title } from 'prace-common-components'
import { Grid } from '@material-ui/core'
import { $GridItem } from './styles'
import { BankProps } from './types'
import { $SaveButton } from '../styles'

export const Bank: FC<BankProps> = ({input, name, handleInput, isMobile, handleProfileUpdate}) => {

  return <>
    <Grid container>
      <$GridItem item xs={12}>
        <Title alternate noMargin>Bank Details</Title>
      </$GridItem>
      <$GridItem item xs={12}>
        <p>{`Bank details are only used if you have/are participating in the ${name || 'PRACE'} scientific review process.`}</p>
      </$GridItem>
      <$GridItem item xs={12} md={7}>
        <Input
          title='Bank Name'
          name='bankName'
          onChange={handleInput}
          value={input.bankName}
          hideItalic
        />
      </$GridItem>
      <$GridItem item xs={12} md={7}>
        <Input
          title="Bank Account Holder's Name"
          name='bankHolderName'
          onChange={handleInput}
          value={input.bankHolderName}
          hideItalic
        />
      </$GridItem>
      <$GridItem item xs={12} md={7}>
        <Input
          title='BIC/Swift Code'
          name='swiftCode'
          onChange={handleInput}
          value={input.swiftCode}
          hideItalic
        />
      </$GridItem>
      <$GridItem item xs={12} md={7}>
        <Input
          title='Bank Account Number'
          name='iban'
          onChange={handleInput}
          value={input.iban}
          hideItalic
        />
      </$GridItem>
      <$GridItem item xs={12} md={7}>
        <Input
          title='Address of the banks branch'
          name='bankAddress'
          onChange={handleInput}
          value={input.bankAddress}
          hideItalic
        />
      </$GridItem>
      <$GridItem item xs={12} md={7}>
        <Input
          title='Bank routing number'
          name='bankRouting'
          onChange={handleInput}
          value={input.bankRouting}
          hideItalic
        />
      </$GridItem>
      <$GridItem item xs={12} md={7}>
        <Input
          title='Bank Remarks'
          name='bankRemarks'
          onChange={handleInput}
          value={input.bankRemarks}
          hideItalic
        />
      </$GridItem>
    </Grid>
    {isMobile && 
      <$SaveButton onClick={handleProfileUpdate}>
        Save Changes
      </$SaveButton>
    }
  </>
}
