import React, { FC, useState, useEffect } from 'react'
import { config, Routes, __PROD__, CDN, HPC_DOMAIN, isEuroHPC } from 'constants/global'
import { TopNavigation } from 'components/Navigation'
import { Footer, useCheckMobileScreen, Maintenance, ExpiredToken, TopBanner, NotificationType, ADMIN_PORTAL_ROLES, ROLES } from 'prace-common-components'
import { UserInfoProvider } from 'components/UserInfo'
import { DashRoutes, routes } from './routes'
import { tabs, gobackConfig } from './config'
import { $Content, $Link } from './styles'
import { useNavigate, useLocation, matchPath } from 'react-router-dom'
import { useLogoutMutation, useRefreshMutation } from 'store/api/auth'
import { RootState } from 'store'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { useLazyGetProfileQuery } from 'store/api/profile'
import { NotificationWrapper } from 'components/NotificationWrapper'
import { LoadingWrapper } from 'components/LoadingWrapper'

const maintenance = false

export const App: FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const profile = useAppSelector((state: RootState) => state.profile.user)
  const user = useAppSelector((state: RootState) => state.auth.user)
  const isAuth = useAppSelector((state: RootState) => state.auth.isAuth)
  const theme = useAppSelector((state: RootState) => state.organization.theme)
  const [refreshToken, { isSuccess, isLoading, isUninitialized: unloadedRefresh }] = useRefreshMutation()
  const [getUserProfile, { isSuccess: successProfile, isFetching, isLoading: isLoadingProfile}]=useLazyGetProfileQuery()
  const [logout] = useLogoutMutation()
  const [initialTab, setInitialTab] = useState(0)
  const [gobackLabel, setGobackLabel] = useState('')
  const [navigations, setNavigations] = useState(true)
  const [openExpire, setOpenExpire] = useState<boolean>(false)
  const pathname = location.pathname
  const isMobile = useCheckMobileScreen()

  /** gets user info */
  useEffect(() => {
    const refresh = async () => {
      try {
        await refreshToken().unwrap()
      } catch (err) {
        console.log(err)
      }
    }
    const getProfile = async (userId: number) => {
      try {
        const resUser = await getUserProfile(userId).unwrap()
        if ([...ADMIN_PORTAL_ROLES, ROLES.SUPER_ADMIN].includes(resUser.role)) {
          dispatch({ type: 'notification', payload: { type: NotificationType.error, msg: 'You dont have permission to access this portal.' } })
          await logout()
        }
      } catch (err) {
        console.log(err)
      }
    }
    if (!user && isAuth) refresh().catch((err) => console.log(err))
    if (user && (user.id !== profile?.id || !profile.email)) getProfile(user.id).catch((err) => console.log(err))
  }, [refreshToken, logout, getUserProfile, user, profile, isAuth, dispatch])

  //TODO: we probably want to move non dashboard content up a level, instead of hiding nav.
  useEffect(() => {
    const gb =
     gobackConfig.find((item: {label: string; path: string}) => matchPath(pathname, item.path))

    if (pathname.startsWith('/auth') ||
    pathname.startsWith('/confirmation') ||
    pathname.startsWith('/registration') ||
    pathname.startsWith('/password') ||
    pathname.startsWith('/forgot')) {
      setNavigations(false)
    } else {
      setNavigations(true)
    }

    if (gb) {
      setGobackLabel((gb as {label: string}).label)
    } else {
      setGobackLabel('')
    }
  }, [pathname])

  useEffect(() => {
    if (user) {
      for(const [idx, tab] of tabs.entries()) {
        if (pathname.includes(tab.route)) setInitialTab(idx)
      }
    }
  }, [user, pathname])

  useEffect(() => {
    const onExpire= () => {
      if(!pathname.includes(Routes.AUTH) && isAuth) setOpenExpire(true)
    }
    document.body.addEventListener('expired', onExpire)
    return () => document.body.removeEventListener('expired', onExpire)
  }, [pathname, isAuth])

  useEffect(() => {
    const scrollToTop= () => {
      try {
        // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
        document.body.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        })
      } catch (error) {
        document.body.scrollTo(0, 0) // fallback for older browsers
      }
    }
    document.body.addEventListener('scrollToTop', scrollToTop)
    return () => document.body.removeEventListener('scrollToTop', scrollToTop)
  }, [])

  /**  Scroll Top each time we change route */
  useEffect(() => {
    document.body.scroll({top: 0})
  },[pathname])

  const handleTabChange = (route: string) => {
    navigate(route)
  }

  const goBack = () => navigate(-1)

  const onLogin = () => {
    setOpenExpire(false)
    navigate(Routes.LOGIN)
  }

  /* TODO: The whole auth handling should be revamped and simpler */
  const waiting = isFetching || isLoading || isLoadingProfile || (isAuth && !user && unloadedRefresh)
  /* if (isFetching || isLoading || isLoadingProfile || (isAuth && !user && unloadedRefresh))
    return <Loading loading /> */

  /* Waiting for the store to be updated */
  const waitingUser = (isSuccess || successProfile) && !user

  const footerLinks = [
    {
      text: 'Contact',
      href: theme?.contact.link || 'https://prace-ri.eu/contact/',
    },
  ]

  return (
    <>
      {__PROD__ ? isEuroHPC ?
        <TopBanner top message='We are currently updating the Regular Access and Extreme Scale Access calls with cut-off dates in 2025. The updated calls will be published as soon as possible.'/>
        :
        <TopBanner top message={
          <div>
            EuroHPC calls and proposals have been successfully migrated to the new EuroHPC Access Portal: 
            <$Link href={HPC_DOMAIN} target='_blank' rel='noopener'>
              https://access.eurohpc-ju.europa.eu.
            </$Link>
            You can continue using your existing login credentials to access it.
          </div>}/>
        : <TopBanner message='This is a development environment!'/>}
      {maintenance ? <Maintenance subtext='We expect to complete the EuroHPC calls migration to the new domain by September 26th, 23:00 CEST.' /> : null}
      {navigations && !maintenance && !waiting && !waitingUser &&
        <>
          <TopNavigation
            tabs={user ? tabs : [{ label: 'Calls', route: Routes.HOME }]}
            initialTab={initialTab}
            onTabChange={handleTabChange}
            name={config.portalName}
            showBackButton={gobackLabel.length > 0}
            backButtonLabel={gobackLabel}
            onGoBackCLick={goBack}
            user={user ? { ...user, firstName: profile?.firstName } : undefined}
            isMobile={isMobile}
          />
          <UserInfoProvider>
            <$Content direction='column'>
              <DashRoutes isMobile={isMobile} />
            </$Content>
          </UserInfoProvider>
          <ExpiredToken open={openExpire} onLogin={onLogin}/>
          {navigations && <Footer
            logo={`${CDN}/logoSquare.svg`}
            name={theme?.organization.name || 'PARTNERSHIP FOR ADVANCED COMPUTING IN EUROPE'}
            homepage={theme?.organization.link || 'https://prace-ri.eu/'}
            links={footerLinks}
            desc={`© ${new Date().getFullYear()} PRACE. All rights reserved. Proudly made in Europe. v${PKG_VERSION}`}
          />}
        </>
      }
      {!navigations && !maintenance && !waiting && !waitingUser && routes()}
      <NotificationWrapper />
      <LoadingWrapper />
    </>
  )
}
