/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { UserSuggestion, UsersState } from './types'
import { usersApi } from 'store/api/users'
import { ROLES } from 'prace-common-components'

const initialState: UsersState = {
  users: [],
  suggestions: {},
}

const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(usersApi.endpoints.getUsersByApplication.matchFulfilled,(state, { payload }) => {
      state.users = payload
    })
    builder.addMatcher(usersApi.endpoints.loadUserSuggestions.matchFulfilled,(state, { payload }) => {
      const { roles, users, page } = payload
      let suggestions = state.suggestions
      roles.forEach((role: ROLES) => {
        const roleData = users.filter((userSuggestion: UserSuggestion) => userSuggestion.role == role)
        suggestions = {
          ...suggestions,
          [role]: {
            data: roleData, page, pageCount: roleData.length,
          }}
      })
      state.suggestions = suggestions
    })
  },
})

export const usersReducer = slice.reducer
