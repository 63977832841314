import React, { useEffect, useRef, useState } from 'react';
import { $Slider, $SliderContainer, $SliderButton, $Container } from './styles';
import { SvgIcon } from '../SvgIcon';
import { SliderElement } from './SliderElement';
import { theme } from '../newTheme';
const numberOfSlides = (maxVisibleSlides, windowWidth) => {
    if (windowWidth > 992)
        return maxVisibleSlides;
    if (windowWidth > 768)
        return 3;
    return 1;
};
export const Slider = ({ children = [], visibleSlides = 3, slideMargin, maxWidth, onClickElement, }) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [scrollSize, setScrollSize] = useState(0);
    const sliderRef = useRef(null);
    const slides = numberOfSlides(visibleSlides, scrollSize);
    const totalSlides = Math.max(children.length - slides, 0);
    useEffect(() => {
        const resizeObserver = new ResizeObserver(entries => { setScrollSize(entries[0].contentRect.width); });
        resizeObserver.observe(sliderRef.current);
    }, [sliderRef]);
    useEffect(() => {
        if (sliderRef && sliderRef.current) {
            if (currentSlide > totalSlides)
                setCurrentSlide(totalSlides);
            sliderRef.current.style.transform = `translate3D(-${currentSlide * (scrollSize / slides)}px, 0, 0)`;
        }
    }, [sliderRef, currentSlide, scrollSize, totalSlides]);
    const handleNextSlide = (next) => {
        setCurrentSlide((slide) => (next ? slide + 1 : slide - 1));
    };
    return (React.createElement($Container, { maxWidth: maxWidth },
        React.createElement($Slider, { visibleSlides: slides },
            React.createElement($SliderContainer, { ref: sliderRef }, children.map((child, i) => (React.createElement(SliderElement, { key: i, visibleSlides: slides, slideMargin: slideMargin, id: i + 1, onClickElement: onClickElement }, child))))),
        currentSlide > 0 && React.createElement($SliderButton, null,
            React.createElement(SvgIcon, { name: 'chevron-left', color: theme.colors.white, viewBox: '7 5 10 15', clickable: true, onClick: () => handleNextSlide(false) })),
        (currentSlide < totalSlides) && React.createElement($SliderButton, { next: true },
            React.createElement(SvgIcon, { name: 'chevron-right', color: theme.colors.white, viewBox: '7 5 10 15', clickable: true, onClick: () => handleNextSlide(true) }))));
};
