import { createSlice } from '@reduxjs/toolkit'
import { ProfileState } from './types'
import { ADMIN_PORTAL_ROLES, ROLES, USER_STATUS } from 'prace-common-components'
import { profileApi } from 'store/api/profile'

const initialState: ProfileState = {
  user: {
    id: 0,
    email: '',
    role: ROLES.USER,
    status: USER_STATUS.Locked,
    firstName: '',
    lastName: '',
    title: '',
    gender: '',
    nationality: '',
    phoneNumber: '',
    dateOfBirth: '',
    iban: '',
    homeAddress: '',
    bankName: '',
    bankHolderName: '',
    bankAddress: '',
    bankRouting: '',
    bankRemarks: '',
    swiftCode: '',
    website: '',
    linkedin: '',
    scholar: '',
    scopus: '',
    researchgate: '',
    link1: '',
    link2: '',
    tags: [],
    temporary: false,
    mandateStartDate: '',
    mandateEndDate: '',
    reviewersAvailability: false,
    fields:[],
    lastLogin: '',
    acceptMailingList: null,
    isReviewer: false,
  },
}

const slice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(profileApi.endpoints.getProfile.matchFulfilled,(state, { payload }) => {
      const { role } = payload
      if (!([...ADMIN_PORTAL_ROLES, ROLES.SUPER_ADMIN].includes(role))) {
        state.user = payload
      }
    })
    builder.addMatcher(profileApi.endpoints.updateProfile.matchFulfilled,(state, { payload }) => {
      if(payload.role == state.user.role) {
        state.user = { ...state.user, ...payload}
      }
    })
  },
})

export const profileReducer = slice.reducer
