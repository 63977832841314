/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { CallsState } from './types'
import { callsApi } from 'store/api/calls'

const initialState: CallsState = {
  data: [],
  total: 0,
}

const slice = createSlice({
  name: 'calls',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(callsApi.endpoints.getCalls.matchFulfilled,(state, { payload }) => {
      state.data = payload.data
      state.total = payload.total
    })
    builder.addMatcher(callsApi.endpoints.countApplied.matchFulfilled,(state, { payload }) => {
      const newCalls = state.data.map((call) => call.id == payload.id ? ({...call,...payload}) : call)
      state.data = newCalls
    })
  },
})

export const callsReducer = slice.reducer
