import React, { useState } from 'react'
import { activeTimeFrame, FlowCall, Slider, CallCard, NewTabs } from 'prace-common-components'
import { useNavigate } from 'react-router-dom'
import { CDN_AVATARS, Routes } from 'constants/global'
import {
  $OpenCalls,
  $PrevCalls,
  $OpenTitle,
  $Background,
  $TabsContainer,
  $CardContainer,
} from './styles'
import Grid from '@material-ui/core/Grid'
import { useGetCallsQuery } from 'store/api/calls'
import { getCallStatus } from 'util/getCallStatus'

const tabs = [{ id: 0, label: 'All Calls' }, { id: 1, label: 'Open Calls' }]

export const Home = () => {
  const navigate = useNavigate()
  const [tab, setTab] = useState<number>(0)
  const { data: calls } = useGetCallsQuery()
  const handleCardClick = (_idx: number, callId: FlowCall['id']) => () => navigate(Routes.CALL(callId))
  const onTabChange = (newTab: number) => setTab(newTab)
  const callsData = calls?.data || []
  const openCalls = callsData.filter((call) => (getCallStatus(call))) || []

  return (
    <>
      <$Background>
        <$OpenCalls>
          <$OpenTitle>Open Calls for Proposals</$OpenTitle>
          <Slider slideMargin={8} maxWidth={1000}>
            {openCalls.map((call, idx: number) =>
              <CallCard
                key={call.id}
                initials={call.title.substring(0, 2).toLocaleUpperCase()}
                title={call.title}
                description={call.description}
                timeframe={activeTimeFrame(call.flow.find((step) => step.order === 0))}
                logoSrc={call.logo ? `${CDN_AVATARS}/${call.logo}` : undefined}
                open={getCallStatus(call)}
                onClick={handleCardClick(idx, call.id)}
              />,
            )}
          </Slider>
        </$OpenCalls>
      </$Background>
      <$PrevCalls container>
        <$TabsContainer item>
          <NewTabs tabs={tabs} value={tab} onChange={onTabChange} />
        </$TabsContainer>
        <$CardContainer container item xs={12} spacing={3}>
          {tab == 0 ?
            callsData.map((call, idx) => <Grid item key={`AllCard-${call.id}`}>
              <CallCard
                initials={call.title.substring(0, 2).toLocaleUpperCase()}
                title={call.title}
                description={call.description}
                timeframe={activeTimeFrame(call.flow.find((step) => step.order === 0))}
                logoSrc={call.logo ? `${CDN_AVATARS}/${call.logo}` : undefined}
                open={getCallStatus(call)}
                onClick={handleCardClick(idx, call.id)}
              />
            </Grid>)
            :
            openCalls.map((call, idx) => <Grid item key={`OpenCard-${call.id}`}>
              <CallCard
                initials={call.title.substring(0, 2).toLocaleUpperCase()}
                title={call.title}
                description={call.description}
                timeframe={activeTimeFrame(call.flow.find((step) => step.order === 0))}
                logoSrc={call.logo ? `${CDN_AVATARS}/${call.logo}` : undefined}
                open={getCallStatus(call)}
                onClick={handleCardClick(idx, call.id)}
              />
            </Grid>)
          }
        </$CardContainer>
      </$PrevCalls>
    </>
  )
}
