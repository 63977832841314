import React, { FC, useState, MouseEvent } from 'react'
import { Select, NewModal, NewButton, SvgIcon, Input, NewDatePicker, PhoneInput, Title, NewCheckbox, isEmail } from 'prace-common-components'
import { GENDERS, NATIONALITIES, TITLES, RESEARCH_FIELD_SUB_TITLES } from 'constants/global'
import { Grid } from '@material-ui/core'
import { $GridItem, $ModalContainer } from './styles'
import { PersonalProps } from './types'
import { $SaveButton } from '../styles'
import { useTheme } from 'styled-components'

const fieldRequired = 'This field is required'
const MIN_AGE = 18
const MAX_AGE = 100
const today = new Date()
const dateMin = new Date(today.getFullYear() - MAX_AGE, today.getMonth())
const dateMax = new Date(today.getFullYear() - MIN_AGE, today.getMonth())

//TODO: Still needs the user profile image upload
export const Personal: FC<PersonalProps> = ({
  input,
  profile,
  formError,
  formErrorNonApplicant,
  handleInput,
  handleSelectChange,
  handleDateChange,
  handleFieldsChange,
  showErrors,
  updateEmail,
  name,
  handleProfileUpdate,
  isMobile,
}) => {
  const [openEmail, setOpenEmail] = useState(false)
  const [error, setError] = useState(false)
  const theme = useTheme() as { colors: { icons: { visibility: string } }}

  const onEditEmail = (e: MouseEvent<any>) => {
    e.stopPropagation()
    setOpenEmail(true)
  }

  const handleClose = () => {
    setOpenEmail(false)
    setError(false)
  }

  const onChangeEmail = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const data = new FormData(e.target as HTMLFormElement)
    const values = Object.fromEntries(data.entries())
    const { email, pass } = values
    if(!pass) return
    if(!email || !isEmail(email as string)) {
      setError(true)
      return
    }
    try {
      await updateEmail(email as string, pass as string)
      handleClose()
    } catch (err) {
      console.log(err)
    }
  }

  return <>
    <div>
      <Grid container>
        <$GridItem item xs={12}>
          <Title alternate>Personal Information</Title>
        </$GridItem>
        <$GridItem item xs={6} md={4}>
          <Input
            title='First name'
            name='firstName'
            required
            error={formError.firstName && showErrors}
            value={input.firstName}
            hideItalic
            description={formError.firstName ? fieldRequired : ''}
            onChange={handleInput}
          />
        </$GridItem>
        <$GridItem item xs={6} md={4}>
          <Input
            title='Last name'
            name='lastName'
            required
            error={formError.lastName && showErrors}
            value={input.lastName}
            hideItalic
            description={formError.lastName ? fieldRequired : ''}
            onChange={handleInput}
          />
        </$GridItem>
        <$GridItem item xs={4}>
          <Select
            title='Title'
            name='title'
            required
            error={formError.title && showErrors}
            items={TITLES.map((title) => ({ label: title, value: title }))}
            value={input.title}
            onChange={handleSelectChange}
          />
        </$GridItem>
        <$GridItem item xs={4}>
          <Select
            title='Gender'
            name='gender'
            required
            error={formError.gender && showErrors}
            errorMsg={formError.gender ? fieldRequired : ''}
            items={GENDERS.map((gender) => ({ label: gender, value: gender }))}
            value={input.gender}
            onChange={handleSelectChange}
          />
        </$GridItem>
        <$GridItem item xs={4}>
          <Select
            title='Nationality'
            name='nationality'
            required
            value={input.nationality}
            error={formError.nationality && showErrors}
            errorMsg={formError.nationality ? fieldRequired : ''}
            items={NATIONALITIES.map((gender) => ({ label: gender, value: gender }))}
            onChange={handleSelectChange}
          />
        </$GridItem>
        <$GridItem item xs={6} md={4}>
          {/* TODO: Check if min and max dates are working */}
          <NewDatePicker
            title='Date of Birth'
            name='dateOfBirth'
            value={input.dateOfBirth}
            error={formError.dateOfBirth && showErrors}
            errorMsg={!input.dateOfBirth ? fieldRequired : formError.dateOfBirth ? 'Invalid date of birth' : ''}
            minDate={dateMin}
            maxDate={dateMax}
            onChange={handleDateChange}
            required
          />
        </$GridItem>
      </Grid>
    </div>
    <div>
      <Grid container>
        <$GridItem item xs={12}>
          <Title subTitle>Contacts</Title>
        </$GridItem>
        <$GridItem item xs={12} md={4}>
          <PhoneInput
            hideItalic
            title='Phone Number'
            value={input.phoneNumber}
            error={formError.phoneNumber && showErrors}
            errorMsg={formError.phoneNumber ? 'Invalid phone number' : ''}
            name='phoneNumber'
            placeholder='123456789'
            onChange={handleInput}
          />
        </$GridItem>
        <$GridItem item xs={12} md={8}>
          <Input
            disabled
            title='E-mail'
            name='email'
            type='email'
            value={profile.email}
            hideItalic
            endAdornment={
              <SvgIcon
                clickable
                noPadding
                color={theme.colors.icons.visibility}
                name='altEdit'
                onClick={onEditEmail}
                size={20}
              />
            }
          />
        </$GridItem>
        <$GridItem item xs={12} md={6}>
          <Input
            title='Address'
            name='homeAddress'
            required
            onChange={handleInput}
            value={input.homeAddress}
            error={formErrorNonApplicant.homeAddress && showErrors}
            errorMsg={formErrorNonApplicant.homeAddress ? fieldRequired : ''}
            hideItalic
          />
        </$GridItem>
        {/* <$GridItem item xs={6}>
          <Input //TODO: Needs BE implementation
            title='City'
            name='city'
            disabled
            hideItalic
          />
        </$GridItem>
        <$GridItem item xs={12} sm={6}>
          <Input //TODO: Needs BE implementation
            title='Appartment, Suite, etc.'
            name='apartment'
            disabled
            hideItalic
          />
        </$GridItem>
        <$GridItem item xs={6} sm={3}>
          <Input //TODO: Needs BE implementation
            title='Postal Code'
            name='postal'
            disabled
            hideItalic
          />
        </$GridItem>
        <$GridItem item xs={6} sm={3}>
          <Input //TODO: Needs BE implementation
            title='Country'
            name='country'
            disabled
            hideItalic
          />
        </$GridItem> */}
      </Grid>
    </div>
    <Grid container>
      <$GridItem item xs={12}>
        <Title subTitle>Research Fields</Title>
      </$GridItem>
      <Grid container item xs={12}>
        <$GridItem item xs={12}>
          <Select
            title='Scientific Field of Expertise #1'
            name='fields'
            error={formError.fields && showErrors}
            errorMsg={formError.fields ? fieldRequired : ''}
            items={RESEARCH_FIELD_SUB_TITLES.map((field) => ({ label: field, value: field }))}
            value={input.fields[0] || ''}
            onChange={handleFieldsChange(0)}
            required
          />
        </$GridItem>
        <$GridItem item xs={12}>
          <Select
            title='Scientific Field of Expertise #2'
            name='fields'
            items={RESEARCH_FIELD_SUB_TITLES.map((field) => ({ label: field, value: field }))}
            value={input.fields[1] || ''}
            onChange={handleFieldsChange(1)}
          />
        </$GridItem>
        <$GridItem item xs={12}>
          <Select
            title='Scientific Field of Expertise #3'
            name='fields'
            items={RESEARCH_FIELD_SUB_TITLES.map((field) => ({ label: field, value: field }))}
            value={input.fields[2] || ''}
            onChange={handleFieldsChange(2)}
          />
        </$GridItem>
      </Grid>
      {/* //TODO: Needs BE implementation */}
      {/* <Grid container item xs={6}>
        <$GridItem item xs={12}>
          <Input
            title='Short Research Description'
            type='text'
            multiline
            minRows={10}
            hideItalic
            name='description'
            disabled
          />
        </$GridItem>
      </Grid> */}
    </Grid>
    <Grid container>
      <$GridItem item xs={12}>
        <Title subTitle>Mailing List</Title>
      </$GridItem>
      <Grid container item xs={12}>
        <$GridItem item xs={12}>
          <NewCheckbox
            title={`I want to receive information about upcoming ${name || 'PRACE and EuroHPC'} events and relevant news.`}
            hideItalic
            name='acceptMailingList'
            value={input.acceptMailingList !== null ? input.acceptMailingList : undefined}
            onChange={(name: string, value: boolean) => handleInput(name, value)}
          />
        </$GridItem>
      </Grid>
    </Grid>
    {isMobile && 
      <$SaveButton onClick={handleProfileUpdate}>
        Save Changes
      </$SaveButton>
    }
    {openEmail &&
      <NewModal open={openEmail} title='Change E-mail' onClose={handleClose}>
        <$ModalContainer>
          <form onSubmit={onChangeEmail} autoComplete='off'>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Input
                  title='New E-mail'
                  name='email'
                  type='email'
                  error={error}
                  errorMsg={error ? 'Invalid e-mail' : ''}
                  required
                  hideItalic
                  autoComplete='off'
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  title='Current Password'
                  name='pass'
                  type='password'
                  required
                  hideItalic
                  autoComplete='new-password'
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent='space-between'>
                  <NewButton type='submit'>Update E-mail</NewButton>
                  <NewButton variant='outlined' onClick={handleClose}>Cancel</NewButton>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </$ModalContainer>
      </NewModal>
    }
  </>
}
