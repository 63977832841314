/** forces a date to utc **OR** returns a `new Date()` with current time */
export default function dateUTC(date: string | Date = new Date()) {
  const theDate = typeof date === 'string' ? new Date(date) : date

  const utc =  Date.UTC(
    theDate.getUTCFullYear(),
    theDate.getUTCMonth(),
    theDate.getUTCDate(),
    theDate.getUTCHours(),
    theDate.getUTCMinutes(),
    theDate.getUTCSeconds(),
  )

  return new Date(utc)
}
