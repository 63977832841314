import styled from 'styled-components'
import { NewButton } from 'prace-common-components'
import { Avatar } from '@material-ui/core'

export const $Profile = styled.div<{isMobile?: boolean}>`
  display: flex;
  width: 100%;
  flex: 1;
  max-width: 1000px;
  padding-top: 30px;
  align-self: center;
  ${({isMobile}) => isMobile && `
    flex-direction: column;
  `}
`

export const $UserInfo = styled.div`
  display: flex;
  flex: 0.4;
  padding: ${({ theme }) => `${theme.spacing(2)} ${theme.spacing(4)}`};
  flex-direction: column;
`

export const $UserCard = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(4)};
  ${({theme}) => theme.elevations[1]};
  button, h3 {
    margin: 0 auto;
  }
`

export const $Avatar = styled(Avatar)`
  margin: 0 auto;
  margin: ${({ theme }) => `${theme.spacing(3)} auto`};
  &.MuiAvatar-root {
    width: 80px;
    height: 80px;
  }
  &.MuiAvatar-colorDefault {
    background-color: ${({ theme }) => theme.colors.secondary};
  }
`

export const $Name = styled.p`
  font-size: 1.2em;
  color: ${({ theme }) => theme.colors.primary};
  margin: 0 0 20px 0;
`

export const $LastLogin = styled.p`
  font-size: 0.9em;
  margin: 0 0 20px 0;
`

export const $SaveButton = styled(NewButton)``

export const $Form = styled.div<{isMobile?: boolean}>`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: ${({isMobile, theme }) => isMobile ? theme.spacing(1) : '0 0 60px 0'};
  > div {
    margin-bottom: 20px;
  }
`

export const $BackButton = styled.div`
  color: ${({ theme }) => theme.colors.darkPrimary};
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(1)};
`
