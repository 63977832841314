import React, { FC, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { APPLICATION_FIELDS, APPLICATION_STATUSES, Routes } from 'constants/global'
import { $ChipContainer, $Chip, $Link, $ApplyNotice, $Home, $NoticeTitle, $ImgContainer, $NoticeItem, $RowContainer, $SvgIcon, $TopContainer } from './styles'
import { getSearchQuery, Title, NewLoading, DataRow, newTheme, NewButton, FILTER_TYPE, DataTable, DataFilter, FLOW_STATUS, ActionsColumn, NestedMenu, ROLES, Select, ROLE_NAME, Grid } from 'prace-common-components'
import { useQuery } from 'util/useQuery'
import apply from 'assets/apply.svg'
import { useDuplicateApplicationMutation, useSearchApplicationsMutation } from 'store/api/applications'

type AllFilterType = { page: number; orderBy: {[key: string]: 'ASC' | 'DESC'}; searchBy: {[key: string]: string}; role?: ROLES}

const limit = 10
const menuItems = [
  {id: 0, label: <><$SvgIcon name='content-copy' size={16} />Duplicate</>},
]

export const CallApplications: FC<{isMobile?: boolean}> = () => {
  const { query } = useQuery()
  const [duplicateApplication, { isLoading: duplicateLoading }] = useDuplicateApplicationMutation()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [selectedId, setSelectedId] = React.useState<number>()
  const pageQuery = parseInt(query.get('page') || '1')
  const roleQuery = query.get('role') || ''
  const order = query.get('order') as unknown as APPLICATION_FIELDS || ''
  const orderDirection: 'ASC' | 'DESC' = query.get('direction') === 'ASC' ? 'ASC' : 'DESC'
  const navigate = useNavigate()
  const [allFilters, setAllFilters] = useState<AllFilterType>({
    searchBy: getSearchQuery(query, APPLICATION_FIELDS),
    orderBy: order ? {[order as APPLICATION_FIELDS]: orderDirection} : {[APPLICATION_FIELDS.SUBMITTED]: 'DESC'},
    page: pageQuery,
    role: roleQuery as ROLES,
  })
  const [searchApplications, { data: applications, isLoading, isUninitialized }] = useSearchApplicationsMutation()
  const applicationsData = applications?.data || []
  const total = applications?.total || 0
  const noApps = !applicationsData.length

  const filteredRoleItems = applications?.roles?.map((r) => ({ label: ROLE_NAME[r], value: r })) || []

  useEffect(() => {
    const getApplications = async () => {
      try {
        const offset = limit * (pageQuery - 1)
        await searchApplications({ ...allFilters, limit, offset }).unwrap()
      } catch (err) {
        console.log(err)
      }
    }
    getApplications().catch((err) => console.log(err))
  }, [searchApplications, allFilters, pageQuery])

  const urlHandler = useCallback((
    order?: { [key: string]: 'ASC' | 'DESC' },
    search?: { [key: string]: string },
    page: number = 1,
    role?: ROLES,
  ) => {
    setAllFilters((prevFilters: AllFilterType) => {
      const newOrder = order ? order : prevFilters.orderBy
      let orderByField = ''
      Object.keys(newOrder).forEach((key: string) => {
        if(newOrder[key]) orderByField = orderByField + `${key}&direction=${newOrder[key]}`
      })
      const newSearch = search ? search : prevFilters.searchBy
      let searchStr = ''
      Object.keys(newSearch).forEach((key: string) => {
        searchStr = searchStr + `&${key}=${newSearch[key as keyof typeof newSearch]}`
      })
      const newRole = role ? role : prevFilters.role
      if(newRole) searchStr = searchStr + `&role=${newRole}`
      navigate(Routes.APPLICATIONS_SEARCH(page, searchStr, orderByField))
      return { page, searchBy: newSearch, orderBy: newOrder, role: newRole }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, query])

  /** change current table page */
  const handleChangePage = (newPage: number) => {
    if(newPage !== pageQuery) urlHandler(undefined, undefined, newPage)
  }

  const onSortColumn = (columnKey: string, direction?: 'ASC' | 'DESC') => {
    urlHandler({[columnKey]: direction as 'ASC' | 'DESC'})
  }

  const onFilters = (search: DataFilter) => {
    urlHandler(undefined, search)
  }

  const onRole = (role: ROLES) => {
    urlHandler(undefined, undefined, undefined, role)
  }

  const handleClick = (e: React.MouseEvent<any>, id: number) => {
    setAnchorEl(e.currentTarget)
    setSelectedId(id)
  }

  const handleItemClick = async (id: StringNumber) => {
    setAnchorEl(null)
    if(selectedId === undefined) return
    switch(id) {
      default: {
        try {
          const payload = await duplicateApplication(selectedId).unwrap()
          navigate(Routes.APPLICATION(payload.uid))
        } catch (err) {
          console.log(err)
        }
      }
    }
  }

  const columns = [
    {
      key: APPLICATION_FIELDS.ID,
      type: FILTER_TYPE.TEXT,
      name: 'Application ID',
      width: 200,
      formatter(props: { row: DataRow }) {
        const { id, uid, status } = props.row
        return (
          <$RowContainer data-simplebar>
            <$Link to={Routes.APPLICATION(uid)}>
              {status === FLOW_STATUS.DRAFT ? `${APPLICATION_STATUSES.DRAFT}-${id}`: uid}
            </$Link>
          </$RowContainer>
        )
      },
    },
    { key: APPLICATION_FIELDS.CALL_TITLE, type: FILTER_TYPE.TEXT, name: 'Call' },
    { key: APPLICATION_FIELDS.STATUS, type: FILTER_TYPE.TEXT, name: 'Status', width: 100,
      formatter(props: { row: DataRow }) {
        const { status } = props.row
        const draft = status === FLOW_STATUS.DRAFT
        return <$ChipContainer data-simplebar>
          <$Chip color={draft ? newTheme.colors.neutral.light : newTheme.colors.secondaries.light}>{draft ? 'Draft' : 'Submitted'}</$Chip>
        </$ChipContainer>
      },
    },
    { key: APPLICATION_FIELDS.PI_NAME, type: FILTER_TYPE.TEXT, name: 'PI Name', width: 160 }, //First and last name
    { key: APPLICATION_FIELDS.PI_AFFILIATION, type: FILTER_TYPE.TEXT, name: 'Affiliation', width: 130 },
    { key: APPLICATION_FIELDS.RESEARCH_FIELD_GROUP, type: FILTER_TYPE.TEXT, name: 'Research F. Group' },
    { key: APPLICATION_FIELDS.RESEARCH_FIELD_TITLE, type: FILTER_TYPE.TEXT, name: 'Research F. Title' },
    { key: APPLICATION_FIELDS.PARTITION, type: FILTER_TYPE.TEXT, name: 'Partition', width: 110 },
    { key: APPLICATION_FIELDS.SUBMITTED, type: FILTER_TYPE.TEXT, name: 'Submit Date', width: 115,
      formatter(props: { row: DataRow }) {
        const { submittedAt = '' } = props.row
        return <div data-simplebar>
          {new Date(submittedAt) > new Date('2000-01-01') ? submittedAt : ''}
        </div>
      }},
    { key: 'actions', type: FILTER_TYPE.NONE, noSort: true, name: 'Actions', width: 30,
      formatter(props: { row: DataRow }) {
        const { id, status } = props.row
        return (
          status === FLOW_STATUS.DRAFT ? 
            null :
            <div data-simplebar>
              <ActionsColumn handleClick={(e: React.MouseEvent<any>) => handleClick(e, id)}/>
            </div>
        )
      },
    },
  ]

  const noApplications = noApps && (!allFilters.searchBy || !Object.keys(allFilters.searchBy).length)
    && !isLoading && !isUninitialized && noApps

  return (
    <>
      <NewLoading loading={isUninitialized || duplicateLoading || isLoading} />
      <$Home>
        <$TopContainer container alignItems='center' justifyContent='space-between'>
          <Grid item xs>
            <Title alternate fontSize={22}>Applications</Title>
          </Grid>
          <Grid item xs={12} sm={4} lg={2}>
            <Select
              name='role'
              placeholder='Select a Role'
              items={filteredRoleItems}
              hideItalic
              onChange={(_n, value) => onRole(value as ROLES)}
              value={allFilters?.role}
            />
          </Grid>
        </$TopContainer>        
        {noApplications && !isLoading ?
          <$ApplyNotice>
            <$ImgContainer>
              <img src={apply} alt='apply' />
            </$ImgContainer>
            <$NoticeItem>
              {(allFilters?.role && allFilters.role !== ROLES.APPLICANT) ? 
                <$NoticeTitle>You have no proposals for the selected role {ROLE_NAME[allFilters.role]}.</$NoticeTitle>
                :
                <$NoticeTitle>It looks like you haven’t <br /> applied to any calls yet.</$NoticeTitle>
              }
            </$NoticeItem>
            {(!allFilters?.role || allFilters?.role === ROLES.APPLICANT) && <$NoticeItem>
              <NewButton onClick={() => navigate(Routes.HOME)}>Apply To Calls</NewButton>
            </$NoticeItem>}
          </$ApplyNotice>
          :
          <>
            <DataTable
              initialSort={[{ columnKey: APPLICATION_FIELDS.SUBMITTED, direction: 'DESC' }]} // Initial Sort
              rows={applicationsData}
              columns={columns}
              total={total}
              page={pageQuery}
              initFilters={allFilters.searchBy}
              noFilterType
              localFilter={false}
              rowsPerPage={limit}
              onChangePage={handleChangePage}
              onSortColumn={onSortColumn}
              onFilters={onFilters}
              noMultipleSorting
            />
            <NestedMenu
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              items={menuItems}
              handleItemClick={handleItemClick}
            />
          </>
        }
      </$Home>
    </>
  )
}
