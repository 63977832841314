import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import { hex2rgba } from 'prace-common-components'

export const $Buttons = styled(Grid)`
  &.MuiGrid-item {
    margin: ${({ theme }) => `${theme.spacing(2)} 0`};
  }
  gap: 20px;
`

export const $CallImage = styled.div<{src?: string}>`
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 50%;
  margin-right: ${({ theme }) => theme.spacing(2)};
  font-weight: bold;
  color: white;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  display: flex;
  background: ${({src, theme }) => src ? 'no-repeat center/100% url('+ src +')' :
    `linear-gradient(45deg, ${theme.colors.secondary} 50%, ${hex2rgba(theme.colors.secondary, 0.88)} 50%)`};  
  background-size: cover;
`

export const $BusinessDetails = styled.div`
  display: flex;
  flex-direction: column;
  height: max-content;
  max-width: 60%;
  margin: 0 100px 0 20px;
  align-self: center;
`

export const $PageTitle = styled.h3`
  margin: ${({ theme }) => `${theme.spacing(1)} 0`};
  color: ${({ theme }) => theme.colors.white};
  font-weight: lighter;
  font-size: 1.5em;
`

export const $CallDetails = styled(Grid)`
  height: max-content;
  margin: 0 auto;
  padding: ${({ theme }) => `${theme.spacing(2)} ${theme.spacing(5)}`};
  max-width: 1200px;
  min-height: max-content;
`

export const $Description = styled.div`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.text.headLine};
  margin: 0 0 20px 0;
  white-space: pre-wrap;
`

//TTODO: Duplicate of Home $PrevCalls
export const $OtherCalls = styled(Grid)`
  height: max-content;
  margin: 0 auto;
  padding: ${({ theme }) => theme.spacing(5)};
  padding-top: ${({ theme }) => theme.spacing(2)};
  max-width: 1200px;
  min-height: max-content;
`

export const $CardStatus = styled.div`
  display: flex;
  align-items: baseline;
`

export const $StatusColor = styled.div<{open?: boolean}>`
  width: 10px;
  height: 10px;
  display: flex;
  border-radius: 50%;
  background-color: ${({ open, theme }) => open ? theme.colors.feedback.success : theme.colors.feedback.error };
`

export const $StatusText = styled.div`
  color: ${({ theme }) => theme.colors.white };
  margin-left: ${({ theme }) => theme.spacing(1)};
`
