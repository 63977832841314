import React, { useState } from 'react'
import { DataTable, Title, DataRow, FILTER_TYPE, newTheme } from 'prace-common-components'
import { Link } from 'react-router-dom'
import { $Chip, $Legacy, $Paper } from './styles'
import { $RowContainer } from './styles'
import { Routes } from 'constants/global'
import { useGetCINESAppsQuery } from 'store/api/cines'

const limit = 10

export const Legacy = () => {
  const [page, setPage] = useState(1)
  const { data: cinesData } =  useGetCINESAppsQuery({ limit, offset: limit * (page - 1) })
  const handleChangePage = (page: number) => setPage(page)

  const columns = [
    {
      key: 'id',
      type: FILTER_TYPE.NONE,
      noSort: true,
      name: 'Proposal ID',
      formatter(props: { row: DataRow }) {
        const { number = 0 } = props.row
        return <$RowContainer data-simplebar><Link to={Routes.CINES(number)}>{number}</Link></$RowContainer>
      },
    },
    { key: 'submitted', type: FILTER_TYPE.NONE, name: 'Submitted', noSort: true,
      formatter(props: { row: DataRow }) {
        const { submitted } = props.row
        return <$RowContainer data-simplebar>{
          <$Chip color={Number(submitted) ? newTheme.colors.primaries.medium : newTheme.colors.secondaries.medium}>
            {Number(submitted) ? 'Yes' : 'No'}
          </$Chip>
        }</$RowContainer>
      },
    },
    { key: 'callName', type: FILTER_TYPE.NONE, name: 'Call Name', noSort: true,
      formatter(props: { row: DataRow }) {
        const { call } = props.row
        return <$RowContainer data-simplebar>{call?.name || ''}</$RowContainer>
      },
    },
    { key: 'user', type: FILTER_TYPE.NONE, name: 'User', noSort: true,
      formatter(props: { row: DataRow }) {
        const { contact_name } = props.row
        return <$RowContainer data-simplebar>{contact_name || ''}</$RowContainer>
      },
    },
    { key: 'userEmail', type: FILTER_TYPE.NONE, name: 'User Email', noSort: true,
      formatter(props: { row: DataRow }) {
        const { contact_email } = props.row
        return <$RowContainer data-simplebar>{contact_email || ''}</$RowContainer>
      },
    },
    { key: 'machine', type: FILTER_TYPE.NONE, name: 'Call Machines', noSort: true,
      formatter(props: { row: DataRow }) {
        const { call } = props.row
        const machines = call?.machine || call?.machinePreparatory || []
        return <$RowContainer data-simplebar>{
          machines?.map((m: any) => m.name).join(', ')
          || call?.machinePreparatory?.map((m: any) => m.name).join(', ')
          || ''
        }</$RowContainer>
      },
    },
  ]
  //TODO: Handle any
  const rows = (cinesData?.data || []).map((row: any) => ({
    ...row,
    contact_name: row.regular?.contact_name || row.preparatory?.contact_name,
    contact_email: row.regular?.contact_email || row.preparatory?.contact_email,
  }))

  return (
    <$Legacy>
      <$Paper>
        <Title alternate fontSize={22}>Legacy</Title>
      </$Paper>
      <DataTable
        noFilters
        rows={rows}
        columns={columns}
        total={cinesData?.total || 0}
        page={page}
        localFilter={false}
        rowsPerPage={limit}
        onChangePage={handleChangePage}
        noFilterType
      />
    </$Legacy>
  )
}
