import { clientApi } from '..'
import { Profile } from 'store/profile/types'
import { UpdateEmailPayload, UpdatePasswordPayload } from './types'
import { withLoading } from 'prace-common-components'

export const profileApi = clientApi.injectEndpoints({
  endpoints: (build) => ({
    getProfile: build.query<Profile, number>({
      ...withLoading,
      query: (id) => ({
        url: `/users/${id}`,
      }),
    }),
    updateProfile: build.mutation<Partial<Profile>, Partial<Profile>>({
      ...withLoading,
      query: ({ id, ...body }) => ({
        url: `/users/${id}`,
        method: 'PATCH',
        body,
      }),
      transformResponse: (_res, _meta, args) => ({ ...args }),
    }),
    updateEmail: build.mutation<Profile['email'], UpdateEmailPayload>({
      ...withLoading,
      query: (body) => ({
        url: '/users/email',
        method: 'PATCH',
        body,
      }),
      transformResponse: (_res, _meta, args) => (args.email),
    }),
    updatePassword: build.mutation<void, UpdatePasswordPayload>({
      ...withLoading,
      query: (body) => ({
        url: '/users/password',
        method: 'PATCH',
        body,
      }),
    }),
  }),
})

export const {
  useLazyGetProfileQuery,
  useUpdateEmailMutation,
  useUpdatePasswordMutation,
  useUpdateProfileMutation,
} = profileApi
