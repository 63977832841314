import React, { FC } from 'react'
import { NewCheckbox } from 'prace-common-components'
import { TermsProps as Props } from './types'
import { $Terms, $TermsLink } from './styles'

const Terms: FC<Props> = ({ checkboxText, privacyURL, termsURL, handleChange }) => {
  const [checked, setChecked] = React.useState(false)

  const handleCheckBoxChange = (value: boolean) => {
    setChecked(value)
    handleChange(value)
  }

  const label = <$Terms>
    <span> {checkboxText} </span>
    { termsURL ? <$TermsLink href={termsURL} target='_blank' rel='noreferrer'>terms of use</$TermsLink> : '' }
    { termsURL && privacyURL ? <span> and </span> : '' }
    { privacyURL ? <$TermsLink href={privacyURL} target='_blank' rel='noreferrer'>privacy policy</$TermsLink> : '' }.
  </$Terms>

  return <NewCheckbox
    hideItalic
    title={label}
    value={checked}
    ariaLabel='accept terms and privacy'
    onChange={(_, value: boolean) => handleCheckBoxChange(value)}
  />
}

export default Terms
