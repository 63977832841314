import { Routes, SHOW_CINES } from 'constants/global'
import { FooterLinkProps, TabProps } from 'prace-common-components'

const defaultTabs: TabProps[] = [
  {
    label: 'Calls',
    route: Routes.HOME,
  },
  {
    label: 'Applications',
    route: Routes.APPLICATIONS,
  },
]

if(SHOW_CINES) defaultTabs.push({
  label: 'Legacy',
  route: Routes.LEGACY,
})

export const tabs: TabProps[] = defaultTabs


export const gobackConfig = [
  /* {
    path: Routes.CALL(':id'),
    label: 'Back to Calls',
  }, */ //TODO: Check if we want these on Call Wizard
  /* {
    path: Routes.APPLICATION(':uid'),
    label: 'Back to Call Applications',
  }, */
]

export const footerLinks: FooterLinkProps[] = [
  {
    text: 'Contact',
    href: 'mailto:peer-review@prace-ri.eu',
  },
]
