import styled from 'styled-components'

export const $Form = styled.form`
  width: 100%;
`

export const $Forgot = styled.a`
  cursor: pointer;
  text-decoration: none;
  margin: 0;
  color: ${({ theme }) => theme.colors.text.label};
  &:hover {
    text-decoration: underline;
  }
`

export const $Error = styled.p`
  margin: 0;
  font-size: 0.9em;
  color: ${({ theme }) => theme.colors.feedback.error};
`

export const $TermsDiv = styled.div`
  text-align: center;
  margin-top: ${({ theme }) => theme.spacing(2)};
  color: ${({ theme }) => theme.colors.text.label};
`
