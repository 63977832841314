import styled from 'styled-components'
import { $ContentProps } from './types'

export const $Content = styled.div<$ContentProps>`
  display: flex;
  flex: 1;
  flex-direction: ${({ direction }) => direction };
  ${({ direction }) =>  direction === 'row' && 'overflow: hidden'};
  padding-bottom: 60px;
`

export const $Link = styled.a`
  color: ${({ theme }) => theme.colors.primary};
  margin: 0 4px;
  text-decoration: underline;
`
