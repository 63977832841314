import styled from 'styled-components'

export const $Terms = styled.div`
  font-size: 0.8em;
`

export const $TermsLink = styled.a<{grey?: boolean}>`
  cursor: pointer;
  text-decoration: none;
  margin: 0;
  color: ${({ theme, grey }) => grey ? theme.colors.text.label : theme.colors.text.link};
  &:hover {
    text-decoration: underline;
  }
`
