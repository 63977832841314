import React, { FC } from 'react'
import { Title, NewDatePicker, RadioOptions } from 'prace-common-components'
import { Grid } from '@material-ui/core'
import { $GridItem } from './styles'
import { InvolvementProps } from './types'
import { $SaveButton } from '../styles'

export const Involvement: FC<InvolvementProps> = ({
  input, name, handleDateChange, handleToggleChange, handleProfileUpdate, isMobile,
}) => {
  return <>
    <Grid container>
      <$GridItem item xs={12}>
        <Title alternate>{`${name || 'PRACE'} Involvement`}</Title>
      </$GridItem>
      <$GridItem item xs={12}>
        <Title subTitle>Scientific Reviewer</Title>
      </$GridItem>
      <$GridItem item xs={12}>
        <RadioOptions
          title={`I am interested in being a Scientific Reviewer for ${name || 'PRACE'}`}
          name='reviewersAvailability'
          items={[{value: 'false', label: 'No'}, {value: 'true', label: 'Yes'}]}
          value={String(input.reviewersAvailability)}
          onChange={handleToggleChange}
          hideItalic
        />
      </$GridItem>
      <$GridItem item xs={12}>
        <Title subTitle>{`${name || 'PRACE'} Access Committee`}</Title>
      </$GridItem>
      <$GridItem item xs={12}>
        <RadioOptions
          title={`Temporary not available as ${name || 'PRACE'} Access Committee member`}
          name='temporary'
          disabled
          items={[{value: 'false', label: 'No'}, {value: 'true', label: 'Yes'}]}
          value={String(input.temporary)}
          onChange={handleToggleChange}
          hideItalic
        />
      </$GridItem>
      <$GridItem item xs={6} md={4}>
        <NewDatePicker
          title='Start Date of Mandate'
          name='mandateStartDate'
          value={input.mandateStartDate}
          onChange={handleDateChange}
        />
      </$GridItem>
      <$GridItem item xs={6} md={4}>
        <NewDatePicker
          title='End Date of Mandate'
          name='mandateEndDate'
          value={input.mandateEndDate}
          onChange={handleDateChange}
        />
      </$GridItem>
    </Grid>
    {isMobile && 
      <$SaveButton onClick={handleProfileUpdate}>
        Save Changes
      </$SaveButton>
    }
  </>
}
