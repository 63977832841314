import React, { FC } from 'react'
import { TopNavigationProps } from './types'
import { useState } from 'react'
import { __PROD__, CDN, Routes } from 'constants/global'
import { Navigation, DropdownItem } from 'prace-common-components'
import { useNavigate, useLocation } from 'react-router-dom'
import { getDefaultRoute } from 'util/getDefaultRoute'
import { useLogoutMutation } from 'store/api/auth'

export const TopNavigation: FC<TopNavigationProps> = ({
  tabs,
  name,
  showBackButton,
  backButtonLabel,
  initialTab,
  onTabChange,
  onGoBackCLick,
  isMobile,
  user,
}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const pathname = location.pathname
  const [logout] = useLogoutMutation()
  const noMatch = !(pathname.includes('calls') || pathname == '/' || initialTab)
  const [selectedTab, selectTab] = useState(noMatch ? -1 : 0)

  const handleLogout = async () => {
    try {
      await logout().unwrap()
    } catch (err) {
      console.log(err)
    }
  }

  const userMenuItems: DropdownItem[] = [
    { label: 'Profile', align: 'flex-end', onClick: () => navigate(Routes.PROFILE)},
    //@ts-ignore TS is messing with align prop, TODO: fix this
    { label: 'Logout', icon: 'logout', align: 'flex-end', onClick: handleLogout},
  ]

  const handleLogoClick = () => {
    if(user) navigate(getDefaultRoute(user?.role))
    else navigate(Routes.HOME)
  }
  const handleSignUp = () => navigate(Routes.REGISTER)
  const handleLogin = () => navigate(Routes.LOGIN)
  return (
    <Navigation
      logo={`${CDN}/logoSquare.svg`}
      tabs={tabs}
      initialTab={initialTab}
      selectTab={selectTab}
      name={name}
      handleLogoClick={handleLogoClick}
      user={user}
      onTabChange={onTabChange}
      selectedTab={selectedTab}
      showBackButton={showBackButton}
      backButtonLabel={backButtonLabel}
      userMenuItems={userMenuItems}
      onGoBackCLick={onGoBackCLick}
      handleSignUp={handleSignUp}
      handleLogin={handleLogin}
      isMobile={isMobile}
      noMatch={noMatch}
      topMargin={__PROD__} // FIXME: Remove after top banner is removed
    />
  )
}
