import React, { FC, useState, useEffect } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import { isValidPassword, isEmail, Input, Landing as LandingContainer, NewButton, NotificationType } from 'prace-common-components'
import { CDN, Routes } from 'constants/global'
import { useForgotMutation, useResetMutation } from 'store/api/auth'
import { useAppDispatch } from 'store/hooks'

export const ForgotPassword: FC = () => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const [forgotPass] = useForgotMutation()
  const [resetPass] = useResetMutation()
  const params: { token?: string } = useParams()
  const [view, setView] = useState<'send' | 'sent' | 'reset'>('send')
  const [state, setState] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    token: '',
  })

  useEffect(() => {
    if (params.token) {
      setView('reset')
      const { email, token } = JSON.parse(atob(params.token))
      if (token) setState((state) => ({...state, email, token}))
      return
    }

    switch(location.pathname) {
      case Routes.FORGOT_PASSWORD:
        setView('send')
        break
      case Routes.FORGOT_PASSWORD_SENT:
        setView('sent')
        break
      default:
        setView('send')
    }

  }, [location.pathname, params.token])

  const errors = {
    email: state.email !== '' && !isEmail(state.email),
    password: state.password !== '' && !isValidPassword(state.password),
    confirmPassword: !!state.password.length && state.password !== state.confirmPassword,
  }

  const handleInputChange = (name: string, value: Nullable<StringNumber>) => {
    setState((state) => ({...state, [name]: value }))
  }

  const handleForgotPassword = async () => {
    if(state.email) {
      try {
        await forgotPass({ email: state.email }).unwrap()
      } catch (err) {
        console.log(err)
      }
    }
  }

  const handleResetPassword = async () => {
    if(state.email && state.password) {
      try {
        await resetPass({ email: state.email, password: state.password, token: state.token }).unwrap()
        dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'Password changed, you can login!' } })
      } catch (err) {
        console.log(err)
      }
    }
  }

  const render = () => {
    switch(view) {
      case 'send':
        return (
          <LandingContainer logo={`${CDN}/logo.png`} image={`${CDN}/forgotPasswordSend.jpg`} title='Forgot Password' description='Enter your recovery information below and we&apos;ll send you an e-mail to recover your password.'>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Input
                  title='E-mail Address'
                  name='email'
                  type='email'
                  required
                  error={errors.email}
                  errorMsg={errors.email ? 'Invalid E-mail Address.' : undefined}
                  value={state.email}
                  onChange={handleInputChange}
                  hideItalic
                />
              </Grid>
              <Grid item xs={12}>
                <NewButton fullWidth onClick={handleForgotPassword}>
                  Send Recovery Link
                </NewButton>
              </Grid>
              <Grid item xs={12}>
                <NewButton variant='outlined' fullWidth onClick={() => navigate(-1)}>
                  Back
                </NewButton>
              </Grid>
            </Grid>
          </LandingContainer>
        )

      case 'sent':
        return (
          <LandingContainer logo={`${CDN}/logo.png`} image={`${CDN}/forgotPasswordSent.jpg`} title='Check your mailbox!' description='A recovery link has been sent&nbsp;
          to your e-mail address. Please follow the instructions to reset your password.'>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <NewButton onClick={() => navigate(Routes.LOGIN)}>
                  Login
                </NewButton>
              </Grid>
            </Grid>
          </LandingContainer>
        )

      default:
        return (
          <LandingContainer logo={`${CDN}/logo.png`} image={`${CDN}/forgotPasswordSend.jpg`} title='Reset Password' description='Enter bellow your new password. Use the toggle show/hide to validate.'>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Input
                  title='Password'
                  placeholder='Password'
                  name='password'
                  type='password'
                  required
                  error={errors.password}
                  errorMsg={errors.password ? 'Password must have one lowercase, one uppercase, one symbol and be at least 12 characters long.' : undefined}
                  value={state.password}
                  onChange={handleInputChange}
                  hideItalic
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  required
                  title='Password'
                  placeholder='Password'
                  name='confirmPassword'
                  type='password'
                  error={errors.confirmPassword}
                  errorMsg={errors.confirmPassword ? 'Passwords mismatch.' : undefined}
                  value={state.confirmPassword}
                  onChange={handleInputChange}
                  hideItalic
                />
              </Grid>
              <Grid item xs={12}>
                <NewButton fullWidth onClick={handleResetPassword}>
                  Reset Password
                </NewButton>
              </Grid>
              <Grid item xs={12}>
                <NewButton variant='outlined' fullWidth onClick={() => navigate(-1)}>
                  Back
                </NewButton>
              </Grid>
            </Grid>
          </LandingContainer>
        )
    }
  }

  return (render())
}
