import { NotificationType } from 'prace-common-components'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useConfirmEmailMutation } from 'store/api/auth'
import { useAppDispatch } from 'store/hooks'

export const ValidateEmail: React.FC = () => {
  const dispatch = useAppDispatch()
  const [confirmEmail] = useConfirmEmailMutation()
  const { token }: { token?: string } = useParams()

  useEffect(() => {
    const validateToken = async (id: string, email: string, token: string) => {
      try {
        await confirmEmail({ id, email, token }).unwrap()
        dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'We have updated your email, you can login!' } })
      } catch (err) {
        console.log(err)
      }
    }
    if (token) {
      const { id, email, token: emailToken } = JSON.parse(atob(token))
      if (id && email && emailToken) {
        validateToken(id, email, emailToken).catch((err) => console.log(err))
      }
    }
  }, [confirmEmail, token])

  return null
}
