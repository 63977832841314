import React, { FC } from 'react'
import { Landing as LandingContainer } from 'prace-common-components'
import { $Description } from './styles'
import { CDN } from 'constants/global'

export const Confirmation: FC<{email?: boolean}> = ({ email }) => {
  return (
    <LandingContainer logo={`${CDN}/logo.png`} image={`${CDN}/register.jpg`} title={email ? 'Email Change' : 'Thanks for joining us!'}>
      <$Description>A <strong>{email ? 'confirmation' : 'verification'} link has been sent</strong> to your e-mail address.
            Please follow the instructions to finish your {email ? 'email change' : 'registration'}!</$Description>
    </LandingContainer>
  )
}
