import { FlowCall, ROLES, withLoading } from 'prace-common-components'
import { clientApi } from '..'
import { CountAppliedResponse } from './type'

export const callsApi = clientApi.injectEndpoints({
  endpoints: (build) => ({
    getCalls: build.query<{ data: FlowCall[]; total: number }, void>({
      ...withLoading,
      query: () => ({ url: '/v2/calls/published' }),
    }),
    getCallCutOffRole: build.query<{ role?: ROLES; canDownloadBundle?: boolean }, FlowCall['id']>({
      ...withLoading,
      query: (id) => ({ url: `/v2/calls/${id}/role` }),
    }),
    countApplied: build.query<CountAppliedResponse, FlowCall['id']>({
      ...withLoading,
      query: (id) => ({ url: `/v2/applications/count/${id}` }),
      transformResponse: (res: CountAppliedResponse, _meta, args) => ({ ...res, id: args }),
    }),
  }),
})

export const {
  useGetCallsQuery,
  useGetCallCutOffRoleQuery,
  useCountAppliedQuery,
} = callsApi
