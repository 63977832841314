import React, { FC } from 'react'
import { Input, NewButton, Title } from 'prace-common-components'
import { Grid } from '@material-ui/core'
import { $GridItem } from './styles'
import { PasswordProps } from './types'

export const Password: FC<PasswordProps> = ({
  changePassword, formPassError, showErrors, passInput, handlePassInput,
}) => {
  const anyError = formPassError.oldPassword || formPassError.newPassword ||
    formPassError.verifyPassword || !passInput.newPassword
  return <>
    <Grid container>
      <$GridItem item xs={12}>
        <Title alternate>Change Password</Title>
      </$GridItem>
      <$GridItem item xs={12} md={7}>
        <Input
          title='Current Password'
          name='oldPassword'
          type='password'
          error={formPassError.oldPassword && showErrors}
          value={passInput.oldPassword}
          onChange={handlePassInput}
          hideItalic
        />
      </$GridItem>
      <$GridItem item xs={12} md={7}>
        <Input
          title='New Password'
          name='newPassword'
          type='password'
          error={formPassError.newPassword && showErrors}
          errorMsg={formPassError.newPassword && showErrors && !!passInput.newPassword ? 'Password must have one lowercase, one uppercase, one symbol and be at least 12 characters long.' : undefined}
          value={passInput.newPassword}
          onChange={handlePassInput}
          hideItalic
        />
      </$GridItem>
      <$GridItem item xs={12} md={7}>
        <Input
          title='Verify New Password'
          name='verifyPassword'
          type='password'
          error={formPassError.verifyPassword && showErrors}
          errorMsg={formPassError.verifyPassword && showErrors ? 'Please input the new password again.' : undefined}
          value={passInput.verifyPassword}
          onChange={handlePassInput}
          hideItalic
        />
      </$GridItem>
      <$GridItem item xs={12}>
        <NewButton disabled={anyError} onClick={changePassword}>Change Password</NewButton>
      </$GridItem>
    </Grid>
  </>
}
