import React from 'react';
import { $CallCard, $CardTop, $CardImage, $CardCutOffText, $Chip, $CardCutOff, $CardStatus, $CardTitle, $CardDescription, $StatusColor, $StatusText } from './styles';
import { newTheme } from '../';
export const CallCard = ({ title, logoSrc, initials, timeframe, description = '', open, ...rest }) => {
    const difference = timeframe && timeframe?.end ? new Date(timeframe.end).getTime() - new Date().getTime() : null;
    const hoursDifference = difference ? (difference / (1000 * 3600)) : -1;
    const timeLeft = hoursDifference >= 0 ? Math.round(hoursDifference / 24) : 0;
    return (React.createElement($CallCard, { ...rest },
        React.createElement($CardTop, null,
            React.createElement($CardImage, { src: logoSrc ? logoSrc : undefined }, logoSrc ? '' : initials),
            hoursDifference >= 0 && React.createElement($CardCutOff, null,
                React.createElement($CardCutOffText, null, "Cut-off ends in"),
                React.createElement($Chip, { alternate: true, color: newTheme.colors.feedback.error }, timeLeft === 0 ? `${Math.round(hoursDifference)} hours` : `${timeLeft} days`))),
        React.createElement($CardTitle, null, title),
        React.createElement($CardStatus, null,
            React.createElement($StatusColor, { open: open }),
            React.createElement($StatusText, null, open ? 'Open' : 'Closed')),
        React.createElement($CardDescription, null, description)));
};
