import Grid from '@material-ui/core/Grid'
import styled from 'styled-components'

//TODO: Repeated code from FormModal
export const $ModalContainer = styled.div`
  min-width: 500px;
`

export const $Container = styled(Grid)`
  margin-top: ${({ theme }) => theme.spacing(3)};
`

export const $POCText = styled.p`
  white-space: pre-wrap;
  overflow-wrap: break-word;
  overflow-y: auto;
  max-height: 40vh;
`
