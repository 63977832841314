import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import { $Badge, Divider } from 'prace-common-components'

export const $CallApplication = styled(Grid)`
  margin-top: ${({ theme }) => theme.spacing(3)};
`

export const $PageTitle = styled.h3`
  margin: ${({ theme }) => `${theme.spacing(1)} 0`};
  color: ${({ theme }) => theme.colors.white};
  font-weight: lighter;
  font-size: 1.5em;
`
export const $ProposalID = styled.div`
  color: ${({ theme }) => theme.colors.white};
`

export const $Partition = styled.div`
  margin-top: ${({ theme }) => theme.spacing(1)};
  margin-left: ${({ theme }) => theme.spacing(2)};
`

export const $PartitionName = styled.span`
  color: ${({ theme }) => theme.colors.primary};
`

export const $CallImage = styled.div<{src?: string}>`
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50%;
  margin-right: ${({ theme }) => theme.spacing(1)};
  font-weight: bold;
  color: white;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  display: flex;
  background: ${({src}) => src ? 'no-repeat center/100% url('+ src +')' : 'linear-gradient(45deg,#E27D24 50%,rgba(226,125,36,0.88) 50%)'};
  background-size: cover;
`

export const $GridCollapseContainer = styled(Grid)`
  height: fit-content;
  position: sticky;
  top: 90px;
  &>div {
    max-width: 300px;
  }
`

export const $ApplicationActions = styled(Grid)`
  padding: ${({ theme }) => `0 ${theme.spacing(3)}`};
  /* &>.MuiGrid-root {
    max-width: 220px;
  } */
`

export const $Buttons= styled(Grid)`
  &.MuiGrid-root {
    margin: ${({ theme }) => `${theme.spacing(2)} 0`};
    max-width: 200px;
  }
`

export const $AssignmentContainer = styled(Grid)`
  margin-top: ${({ theme }) => theme.spacing(2)};
  h4 {
    margin-left: ${({ theme }) => theme.spacing(0.5)};
  }
`

export const $ActionsContainer = styled.div`
  width: 100%;
  margin: ${({ theme }) => `${theme.spacing(1)} 0`};
  margin-left: ${({ theme }) => theme.spacing(2)};
  button {
    max-width: 200px;
  }
`

export const $AddAssignment = styled(Grid)`
  &.MuiGrid-root {
    width: 100%;
    padding: 0;
  }
`

export const $Chip = styled($Badge)`
  font-size: 0.9em;
  margin: 0;
  margin-top: ${({ theme }) => theme.spacing(1)};
  padding: ${({ theme }) => `0 ${theme.spacing(1)}`};
`

export const $Divider = styled(Divider)`
  &.MuiDivider-root {
    margin-bottom: ${({ theme }) => theme.spacing(1)};
  }
`

export const $ReviewContainer = styled.div`
  font-size: 0.9em;
  max-height: 120px;
  width: 100%;
  overflow-y: auto;
`

export const $ReviewEntry = styled.div`
  margin: ${({ theme }) => theme.spacing(0.5)};
  margin-left: ${({ theme }) => theme.spacing(1)};
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  white-space: pre-line;
`

export const $AlertContent = styled(Grid)`
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`

export const $WarningContainer = styled(Grid)`
  &.MuiGrid-item {
    margin-left: ${({ theme }) => theme.spacing(4)};
    width: 100%;
  }
`

export const $WarningGrid = styled(Grid)`
  height: 100%;
  padding: ${({ theme }) => theme.spacing(0.5)};
  border-radius: ${({ theme }) => theme.spacing(1)};
  background-color: ${({ theme }) => theme.colors.secondaryShades[80]};
  &.MuiGrid-container {
    width: fit-content;
  }
`

export const $Warning = styled.div`
  margin-right: ${({ theme }) => theme.spacing(1)};
  color: ${({ theme }) => theme.colors.white};
  font-size: 0.9em;
`

export const $FormContainer = styled(Grid)<{ isMobile?: boolean }>`
  ${({isMobile}) => isMobile && 'padding: 0 16px;'}
`
